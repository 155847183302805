import {useEffect, useRef, useState} from "react";
import FramesListHtml from "./FramesListHtml";
import ProgressBar from "./ProgressBar";

function FramesScroll() {
  const framesPath = (parseInt(window.location.hash.replace(/[^0-9]/, '')) || 0).toString().padStart(3, "0");

  const seekSpeed = 1;
  const [framesCount, setFramesCount] = useState(0);
  const framesList = useRef([]);
  const framesLoading = useRef([]);
  const [activeFrame, setActiveFrame] = useState(0);

  const [loadingCount, setLoadingCount] = useState(0);

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const containerRef = useRef(null);

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const overlayRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);

  loadFramesInfo();

  function loadFramesInfo() {
    if (framesCount > 0) return preloadFrames();

    console.log({framesPath}, window.location.hash);

    fetch('frames/index.json').then(r => r.json()).then(info => {
      if (!info[framesPath]) {
        return console.error(`Path "${framesPath} not exists"`);
      }

      setFramesCount(info[framesPath].count);
      setActiveFrame(Math.floor(info[framesPath].count / 2));
    });
  }

  function preloadFrames() {
    if (framesLoading.current.length) return;

    for (let i = 0; i < framesCount; i++) {
      framesList.current.push(`frames/${framesPath}/${i+1}.png`);

      framesLoading.current.push(new Promise(resolve => {
        let link = document.createElement("link");
        link.rel = "preload";
        link.href = framesList.current[i]
        link.onload = link.onerror = () => {
          resolve();
          setLoadingCount(prev => prev + 1);
        };
        link.as = "image";
        document.head.append(link);
      }))
    }

    Promise.all(framesLoading.current).then(() => {
      console.log('loaded');
      setIsLoading(false);
    });
  }

  const prevSeek = useRef(null);
  const prevCall = useRef(new Date());
  const seek = (e) => {
    if (!prevSeek.current)
      return prevSeek.current = e;

    if (e.pageX === 0 && e.pageY === 0)
      return;

    const isTouch = !!e.touches
    e = isTouch ? e.touches[0] : e;
    let speedX = prevSeek.current.screenX - e.screenX;
    let speedY = prevSeek.current.screenY - e.screenY;
    if (Math.abs(speedX) < 10) return;

    const minDelayMs = 5;
    const maxDelayMs = 100;
    const maxSpeed = 150;
    const speed = Math.min(Math.abs(speedX), maxSpeed);
    const speedPerc = 1 - (speed / maxSpeed);

    const delay = minDelayMs + speedPerc * (maxDelayMs - minDelayMs);

    if (new Date() - prevCall.current < delay) return;
    prevCall.current = new Date();

    updateFrame(speedX);

    prevSeek.current = e;
  }

  const seekEnd = () => {
    prevSeek.current = null;
  }

  function updateFrame(speed) {
    const delta = speed > 0 ? -seekSpeed : seekSpeed;
    setActiveFrame(prevValue => Math.min(Math.max(0, prevValue + delta), framesCount - 1));
  }

  const mouseDownInterval = useRef(null);

  function handleMouseDown(speed) {

    if (mouseDownInterval.current) return;
    updateFrame(speed);

    mouseDownInterval.current = setInterval(() => {
      if (!mouseDownInterval.current) return;
      clearInterval(mouseDownInterval.current);

      mouseDownInterval.current = setInterval(() => {
        updateFrame(speed);
      }, 100);
    }, 300);
  }

  function handleMouseUp() {
    clearInterval(mouseDownInterval.current);
    mouseDownInterval.current = null;
  }

  return (
    <div className="App frames-scroll">
      <div className="viewer-container">
        <ProgressBar
          percent={(isLoading ? loadingCount : activeFrame) / (framesCount - 1)}
          text={isLoading ? "loading" : ""}
        />
        <div
          ref={containerRef}
          className={`frames-container ${isLoading ? "_loading" : ""}`}>
          <FramesListHtml frames={framesList.current} activeInd={activeFrame} isLoading={isLoading}/>
          <div className="icons-container">
            <div className="top">
              <img className="logo" src="assets/img/logo.png"/>
              <img className="icons" src="assets/img/3d.png"/>
            </div>

            <div className="middle arrows">
              <span className="arrow left" onMouseDown={() => handleMouseDown(1)} onMouseUp={handleMouseUp}/>
              <span className="arrow right" onMouseDown={() => handleMouseDown(-1)} onMouseUp={handleMouseUp}/>
            </div>

            <div className="bottom">
              <div className="arrow right"></div>
              <div className="arrow left"></div>
            </div>
          </div>
        </div>
        <div
          ref={overlayRef}
          className="seek-overlay"
          draggable={true}
          onTouchMove={seek} onDrag={seek}
          onTouchEnd={seekEnd} onDragEnd={seekEnd}
        />
      </div>
    </div>
  );
}

export default FramesScroll;
